import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import InterfaceCanvas from '../_app/cuchillo/layout/InterfaceCanvas';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Sizes } from '../_app/cuchillo/core/Sizes';
import Hud from './Hud';

export default class GraphSteps {
  static canvas = InterfaceCanvas.canvas || document.createElement('canvas');
  static ctx = InterfaceCanvas.ctx || this.canvas.getContext('2d');
  static x;
  static y;
  static width = 0;
  static height = 0;
  static steps = [];
  static limits = {y0:0,y1:0};
  static old = [0,0];  
  static time = 0;
  static grid;
  
  static init(__dom) {
    this.dom = __dom;
    this.resize();

    //setup steps
    for(let i=0;i<4;i++) {
      const scrollVal = Maths.maxminRandom(6, 2)/10;
      const extVal = Maths.maxminRandom(4, 2)/10;
      this.steps.push({
        value:0,
        scrollVal: scrollVal,
        extVal: extVal,
        time: Maths.maxminRandom(1000, 1),
        amplitude: Maths.maxminRandom(100, 5)/10000,
        mouseMod: 1 - scrollVal - extVal
      });
    }
  }

  static loop() {
    this.time++;
    //MOUSE
    /*this.steps[0] = Math.min(1,Math.abs(Maths.normalize(200,0,Scroll.speed)));
    this.steps[1] = Interaction.positions.mouse.x/Metrics.WIDTH;
    this.steps[2] = Interaction.positions.mouse.y/Metrics.HEIGHT;
    this.steps[3] = Math.min(1,Math.abs(Maths.normalize(300,0,Scroll.speed)));*/

    

    const inc = 1/this.steps.length;
    for(let i=0;i<this.steps.length;i++) {
      const progress = Scroll.engine? Scroll.engine.progress : 0;
      const p0 = inc * (i * .8);
      const p1 = inc * (i + 1);
      this.steps[i].value = Math.max(0,Math.min(this.steps[i].scrollVal, Maths.lerp(0,1, Maths.normalize(p1,p0,progress))));
      this.steps[i].value += Maths.lerp(0, this.steps[i].extVal,Math.abs(Math.sin((this.steps[i].time+this.time)*this.steps[i].amplitude)));
    }

    if(this.steps.length > 0) {
      this.steps[0].value += Maths.lerp(this.steps[0].mouseMod, 0, Interaction.positions.mouse.y/Metrics.HEIGHT);
      this.steps[1].value += Maths.lerp(0, this.steps[1].mouseMod, Interaction.positions.mouse.x/Metrics.WIDTH);
      this.steps[2].value += Maths.lerp(0, this.steps[2].mouseMod, Interaction.positions.mouse.y/Metrics.HEIGHT);
      this.steps[3].value += Maths.lerp(this.steps[3].mouseMod, 0, Interaction.positions.mouse.x/Metrics.WIDTH);
    /*
    this.steps[3].value += Maths.lerp(0, this.steps[3].extVal,Math.abs(Math.sin((this.steps[3].time+this.time)*this.steps[0].amplitude)));
    
    
    */
    //this.steps[3] = Math.min(1,Math.abs(Maths.normalize(300,0,Scroll.speed)));
    }

   
    
    
   this.old = [Interaction.positions.mouse.x,Interaction.positions.mouse.y];

    //CLEAR
   

    //BOX
    this.ctx.beginPath();
    this.ctx.rect(this.x, this.y, this.width, this.height);
    this.ctx.strokeStyle = "#FEEE08";
    this.ctx.stroke();
    this.ctx.closePath();

    let oldY;
    this.steps.map((item, index) => {
        let posY = this.y + Maths.lerp(this.limits[1], this.limits[0], item.value);

         posY = Math.round(posY / this.grid) * this.grid;

        const posX0 = Math.max(this.x + this.grid,this.x + this.thumbSize * index);
        const posX1 = Math.min(this.x + this.width - this.grid,this.x + this.thumbSize * (index + 1));

        this.ctx.beginPath();
        if(oldY) {
          this.ctx.moveTo(posX0, oldY);
          this.ctx.lineTo(posX0, posY);
        }
        this.ctx.moveTo(posX0, posY);
        this.ctx.lineTo(posX1, posY);
        this.ctx.strokeStyle = "#FEEE08";
        this.ctx.stroke();
        this.ctx.closePath();

        oldY = posY;
    });
  }

  static resize() {
    const size = this.dom.getBoundingClientRect(); 

    this.x = size.x * Sizes.RATIO_HUD;
    this.y = size.y * Sizes.RATIO_HUD;
    this.width = size.width * Sizes.RATIO_HUD;
    this.height = size.height * Sizes.RATIO_HUD;

    this.limits = [Hud.grid / 2, this.height - Hud.grid / 2];
    this.thumbSize = this.width / this.steps.length;
    this.grid = Hud.grid / 2;

    this.loop();
  }
}

