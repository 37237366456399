import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from "../_app/cuchillo/scroll/VScroll_Item";
import { Shffl } from '../components/Shffl';
import { GetBy } from '../_app/cuchillo/core/Element';
import Hud from '../hud/Hud';
import HudConsole from '../hud/HudConsole';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Basics } from '../_app/cuchillo/core/Basics';

export default class ScrollItem__Default extends VScroll_Item {
  title;
  text;
  hudLink;
  consoleText = "";
  consoleArt = "";
  isNeedClearInfo = true;
  hasTitleConsole = true;
  idTimerArt = 0;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.title = GetBy.class("__title", this.item)[0]? GetBy.class("__title", this.item)[0].textContent : ""; 
    this.text = GetBy.class("__text", this.item)[0]? GetBy.class("__text", this.item)[0].textContent : "";
    this.hudLink = GetBy.class("__linkHud", this.item)[0]? GetBy.class("__linkHud", this.item)[0].textContent : "";
    this.consoleText = GetBy.class("__console", this.item)[0]? GetBy.class("__console", this.item)[0].textContent : "";
    this.consoleTitle = GetBy.class("__consoleTitle", this.item)[0]? GetBy.class("__consoleTitle", this.item)[0].textContent : "";
    this.consoleArt = GetBy.class("__art", this.item);
    this.opts.offsetShow = Metrics.HEIGHT * .5;    

    this.onShow = () => { this.showInfo() };
    this.onHide = () => { clearTimeout(this.idTimerArt); };
  }

  showInfo() {
    if(this.title === Basics.oldTitle) return;

    Basics.oldTitle = this.title;
    if(this.isNeedClearInfo) Hud.info.clear();
    if(this.title.trim() != "") Hud.info.h2("\\\\" + this.title);
    Hud.info.log(this.text);
    if(this.hudLink.trim() != "") Hud.info.link(this.hudLink);

    if(this.hasTitleConsole) {
      Hud.console.block(this.title);
    }

    if(this.consoleArt.length > 0) {
      Hud.console.art(this.consoleArt[0].textContent);
    }

    if(this.consoleArt.length > 1) {
      this.initArt();
    }

    Hud.console.h3(this.consoleTitle);
    Hud.console.log(this.consoleText);
  }

  initArt() {
    let actual = 0;
    let limit = this.consoleArt.length;

    const call = () => {
      actual++;
      if(actual === limit) {
        actual = 0;
      }
      Hud.console.art(this.consoleArt[actual].textContent);

      this.idTimerArt = setTimeout(call, 1000);
    }

    this.idTimerArt = setTimeout(call, 1000);
  }

  resize(__w,__h) {
    super.resize(__w,__h);

    this.opts.offsetShow = Metrics.HEIGHT * .5;    
  }
}

Scroll._registerClass('none', ScrollItem__Default);
