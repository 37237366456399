export default `
    #define PI 3.1415926538

    varying vec2 vUv;
    uniform float progress;
    uniform float alpha;
    uniform float imageScale;

    void main() {
        vUv = uv;

        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;