import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import InterfaceCanvas from '../_app/cuchillo/layout/InterfaceCanvas';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Sizes } from '../_app/cuchillo/core/Sizes';
import Hud from './Hud';
import { isSmartphone } from '../_app/cuchillo/core/Basics';

export default class HudBG {
  static canvas = InterfaceCanvas.canvas || document.createElement('canvas');
  static ctx = InterfaceCanvas.ctx || this.canvas.getContext('2d');
  static dom;
  static domLogo;
  static domHeader;
  static x;
  static y;
  static width = 0;
  static height = 0;
  static yHeader = 0;
  static curve = 0;
  static points = [];
     
  static init(__dom, __domLogo,__domHeader) {
    this.dom = __dom;
    this.domLogo = __domLogo;
    this.domHeader = __domHeader;
    this.resize();
  }

  static loop(isClear) {
    this.time++;

    const drawLine = (x,y) => {

    }

    const drawBox = (x, y, w, h, r) => {
      if (w < 2 * r) r = w / 2;
      if (h < 2 * r) r = h / 2;
      this.ctx.beginPath();
      this.ctx.moveTo(x+r, y);
      
      //RIGHT TOP
      this.ctx.arcTo(x+w, y,   x+w, y+h, r);
      
      //RIGHT BOTTOM
      this.ctx.arcTo(x+w, y+h, x,   y+h, r);

      //LEFT BOTTOM
      this.ctx.arcTo(x,   (y+h) - 100, x,   y,   r);
      //this.ctx.lineTo(x+100,   (y+h) - 100);

      //LEFT TOP
      this.ctx.arcTo(x,   y,   x+w, y,   r);
      
      
      this.ctx.strokeStyle = "#FEEE08";
      this.ctx.stroke();
      this.ctx.closePath();
    }

  //  drawBox(this.x, this.y, this.width, this.height, this.curve, "#FEEE08");


    
    this.ctx.beginPath();
    this.ctx.moveTo(this.x,this.y + this.curve);
    
    this.ctx.lineTo(this.points[0].x, this.points[0].y - this.curve);
    this.ctx.quadraticCurveTo(this.points[0].x, this.points[0].y, this.points[0].x+this.curve, this.points[0].y);
    
    this.ctx.lineTo(this.points[1].x - this.curve * 1.5, this.points[1].y);
    this.ctx.quadraticCurveTo(this.points[1].x - this.curve * .5, this.points[1].y, this.points[1].x, this.points[1].y + this.curve);

    this.ctx.lineTo(this.points[2].x - this.curve, this.points[2].y - this.curve);
    this.ctx.quadraticCurveTo(this.points[2].x - this.curve * .25, this.points[2].y, this.points[2].x + this.curve, this.points[2].y);

    this.ctx.lineTo(this.points[3].x - this.curve, this.points[3].y);
    this.ctx.quadraticCurveTo(this.points[3].x, this.points[3].y, this.points[3].x, this.points[3].y - this.curve);

    this.ctx.lineTo(this.points[4].x, this.points[4].y + this.curve);
    this.ctx.quadraticCurveTo(this.points[4].x, this.points[4].y, this.points[4].x - this.curve, this.points[4].y);

    this.ctx.lineTo(this.points[5].x + this.curve, this.points[5].y);
    this.ctx.quadraticCurveTo(this.points[5].x, this.points[5].y, this.points[5].x, this.points[5].y + this.curve);
    
    //HEADER
    this.ctx.moveTo(this.x,this.yHeader);
    this.ctx.lineTo(this.x+this.width,this.yHeader);

    this.ctx.globalAlpha = isClear? 1 : .3;
    this.ctx.strokeStyle = "#FEEE08";
    this.ctx.stroke();
    this.ctx.closePath();
    this.ctx.globalAlpha = 1;
   
  }

  static resize() {
    const size = this.dom.getBoundingClientRect(); 
    const sizeLogo = this.domLogo.getBoundingClientRect();
    const sizeHeader = this.domHeader.getBoundingClientRect();

    this.x = size.x * Sizes.RATIO_HUD;
    this.y = size.y * Sizes.RATIO_HUD;
    this.width = size.width * Sizes.RATIO_HUD;
    this.height = size.height * Sizes.RATIO_HUD;
    this.curve = Hud.grid*.5;

    this.yHeader = this.y + sizeHeader.height;

    const marginLogo = isSmartphone? Hud.grid * 2 : Hud.grid * 4;

    this.points = [
      {x:this.x, y:this.y+this.height-sizeLogo.height - marginLogo},
      {x:sizeLogo.x+sizeLogo.width, y:this.y+this.height-sizeLogo.height - marginLogo},
      {x:sizeLogo.x+sizeLogo.width * 1.25, y:this.y+this.height},
      {x:this.x+this.width, y:this.y+this.height},
      {x:this.x+this.width, y:this.y},
      {x:this.x, y:this.y},
    ];
  }
}

