import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Default from './Default';

export default class Article extends Default {

  constructor() {
    super();
  }

  //SHOW
  beforeShow () {
    
  }

  afterShow() {
    super.afterShow();
  }

  //HIDE
  beforeHide () {
    document.body.classList.remove("body-legal");
  }
}

ControllerPage._addPage("article", Article)
