import Hud from '../hud/Hud';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { Maths } from '../_app/cuchillo/utils/Maths';
import ScrollItem__Default from "./ScrollItem__Default";

class ScrollItem__NFTs extends ScrollItem__Default {
  domTitles;
  domVisor;
  domName;
  domFaction;
  titlesLimits = {} 
  visorLimits = {} 
  images = []; 
  titles = []; 
  consoleTexts = []; 
  texts = []; 
  old = 0;
  oldFaction = -1;
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.domTitles = GetBy.class("__titlesFactions", this.item)[0];
    this.domVisor = GetBy.class("__visor", this.item)[0];
    this.domFaction = GetBy.class("__captionFaction", this.item)[0];
    this.domName = GetBy.class("__captionName", this.item)[0];
    this.images = Array.from(GetBy.class("__imageNFT", this.item));
    this.titles = Array.from(GetBy.class("__titleFaction", this.item));

    this.setupTexts();

    this.onMove = (__position, __progress) => {
      const posTitles = Maths.lerp(this.titlesLimits.y1, this.titlesLimits.y0, this.progressInside);
      this.domTitles.style.transform = CSS.translate3D(0, posTitles, 0);

      const posVisor = Maths.lerp(this.visorLimits.y0, this.visorLimits.y1, .5);
      this.domVisor.style.transform = CSS.translate3D(0, posVisor, 0);

      this.loop();
    }
  }

  setupTexts() {
    const domConsoleTexts = Array.from(GetBy.class("__consoleTexts", this.item));
    domConsoleTexts.map((item, index) => {
      this.consoleTexts.push({
        name:GetBy.class("__consoleNftTitle", item)[0].textContent,
        text:GetBy.class("__consoleNft", item)[0].textContent
      });
    });

    this.images.map((item, index) => {
      this.texts.push({
        faction:item.getAttribute("data-faction"),
        name:item.getAttribute("title")
      });
    });

    this.images[0].style.visibility = "visible";
  }

  loop() {
    const actual = Math.ceil(this.images.length * (1 - this.progressInside));

    if(actual < 0 || actual >= this.images.length) {
      this.oldFaction = -1;
      return;
    }

    const faction = this.images[actual].getAttribute("data-faction");

    if(this.consoleTexts.length == 0) return;

    this.domFaction.textContent = this.consoleTexts[faction].name;
    this.domName.textContent = this.texts[actual].name;

    this.images[actual].style.visibility = "visible";
    if(actual !== this.old && this.old != 0) {
      this.images[this.old].style.visibility = "hidden";
    }
    this.old = actual;

    if(faction!=this.oldFaction) {
      Hud.console.h3(this.consoleTexts[faction].name); 
      Hud.console.log(this.consoleTexts[faction].text);
      if(this.titles[this.oldFaction]) {
        this.titles[this.oldFaction].classList.remove("--active");
      }
      this.titles[faction].classList.add("--active");
    }
    this.oldFaction = faction;
  }

  resize(__w, __h) {
    super.resize(__w,__h);

    const size = this.domTitles.getBoundingClientRect(); 
    const sizeVisor = this.domVisor.getBoundingClientRect(); 

    this.titlesLimits = {
      y0: 0,
      y1: Metrics.HEIGHT - size.height
    }

    this.visorLimits = {
      y0: 100,
      y1: Metrics.HEIGHT - 100 - sizeVisor.height
    }
  }

  
}

Scroll._registerClass('NFTs', ScrollItem__NFTs);
