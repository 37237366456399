import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { Maths } from '../_app/cuchillo/utils/Maths';
import ScrollItem__Default from "./ScrollItem__Default";

class ScrollItem__VideoIntro extends ScrollItem__Default {
  hasMove = false;
  hasTitleConsole = false;
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    const video = GetBy.selector("video", __link)[0];
  
    this.onMove = () => {      
      const norm = Maths.normalize(.5, 1, this.progress);
      const scale = Maths.lerp(1.1, 1, norm);
      video.style.opacity = norm;
      video.style.transform = CSS.scale3D(scale,scale,1);
    };

    this.onShow = () => {
      video.play();
      this.showInfo();
    }

    this.onHide = () => {
      video.pause();
    }
  }

  
}

Scroll._registerClass('VideoIntro', ScrollItem__VideoIntro);
