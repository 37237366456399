import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Sizes } from '../_app/cuchillo/core/Sizes';
import InterfaceCanvas from '../_app/cuchillo/layout/InterfaceCanvas';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';
import BezierEasing from 'bezier-easing'
import { Functions } from '../_app/cuchillo/utils/Functions';
import Hud from './Hud';

export default class GlobeHud {
  static canvas = InterfaceCanvas.canvas || document.createElement('canvas');
  static ctx = InterfaceCanvas.ctx || this.canvas.getContext('2d');
  static dom;
  static x;
  static y;
  static markerSize = 0;
  static width = 0;
  static height = 0;
  static progress = 0;  
  static offset = .32;
  static lines = new Array(8);
  static limits = {x0:0,y0:0,x1:0,y1:0};
  static time = 0;
  static speed = 0;

  static ellipseSize = {width:100, height:50}
  
  
  static init(__dom) {
    this.dom = __dom;
    this.resize();

    this.lines = new Array(Math.round(0 + 2/this.offset));
    for(let i=0, j=this.lines.length; i<j; i++) {
      this.lines[i] = 1 - (this.offset * i);
    }
  }

  static loop() {
    this.time++;
    
    this.ctx.beginPath();
    this.ctx.ellipse(this.ellipseSize.x, this.ellipseSize.y, this.ellipseSize.width, this.ellipseSize.height, 0, 0, Math.PI * 2);  
    this.ctx.strokeStyle = "#FEEE08";
    this.ctx.stroke();
    this.ctx.closePath();
    
    const drawLine = (progress) => {
      const startAngle = progress>=0? Math.PI * .5 : Math.PI * 1.5;
      const endAngle = progress>=0? Math.PI * 1.5 : Math.PI * .5;

      this.ctx.beginPath();
      this.ctx.ellipse(this.ellipseSize.x, this.ellipseSize.y, this.ellipseSize.width*Math.abs(progress), this.ellipseSize.height, 0, startAngle, endAngle);  
      this.ctx.strokeStyle = "#FEEE08";
      this.ctx.stroke();
      this.ctx.closePath();
    }

    for(let i=0, j=this.lines.length; i<j; i++) {

      this.lines[i] -= this.speed;
      if(this.lines[i] < -1) {
        this.lines[i] = 1 + (1 + this.lines[i]);
      } else if(this.lines[i] > 1) {
        this.lines[i] = -1 + (this.lines[i] - 1);
      } 

      drawLine(this.lines[i]);
    }      
  }

  static resize() {
    const size = this.dom.getBoundingClientRect(); 

    this.x = size.x * Sizes.RATIO_HUD;
    this.y = size.y * Sizes.RATIO_HUD;
    this.width = size.width * Sizes.RATIO_HUD;
    this.height = size.height * Sizes.RATIO_HUD;

    this.markerSize = Metrics.parseSize("10fpx") * Sizes.RATIO_HUD

    this.ellipseSize = {
      x: this.x + this.width / 2,
      y: this.y + this.height / 2,
      width: (this.width - Hud.grid * 4) / 2,
      height: (this.height - Hud.grid * 2) / 2
    }

    this.limits = {
      x0: this.x + this.markerSize,
      y0: this.y,
      x1: this.x + (this.width - this.markerSize),
      y1: this.y + this.height
    }

    this.loop();
  }
}

