import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Scrollbar from '../_app/cuchillo/scroll/Scrollbar';
import Page from '../_app/cuchillo/pages/Page';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Wrap from '../layout/Wrap';
import { isMobile, isSmartphone } from '../_app/cuchillo/core/Basics';
import { Videos } from '../_app/cuchillo/components/Videos';
import { Acordions } from '../_app/cuchillo/components/Acordions';
import ScrollbarRombo from '../layout/ScrollbarRombo';
import Hud from '../hud/Hud';
import { GetBy } from '../_app/cuchillo/core/Element';

export default class Default extends Page {

  constructor() {
    super();

    Videos.init();
    Acordions.init();
  }

  //SHOW
  beforeShow () {
    
  }

  show__effect (__call) {
    Wrap.show(() => { 
      setTimeout(()=> {
        Hud.show(()=> {
          Scroll.init(Scroll.AXIS_Y, { smooth: !isSmartphone, multiplicator: 1 });
          Scroll.setScrollbar(new Scrollbar(GetBy.id("HudScrollbar")));
          Scroll.start();
          this.afterShow();
        });
      }, 1);
    });
  }

  afterShow () {
    super.afterShow();

    const anchor = window.location.href.split("?section=")[1];
    if(anchor) {
      setTimeout(()=> {Scroll.gotoAnchor(anchor)},1000);
    }
  }

  //HIDE
  beforeHide () { }
  hide__effect () {
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide () {
    Scroll.dispose();
    super.afterHide();
  }

  //RESIZE
  resize () {
    super.resize();
    Acordions.resize();
  }

  //LOOP
  loop () {
    if (this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage("default", Default)
