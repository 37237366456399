import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Sizes } from '../_app/cuchillo/core/Sizes';
import InterfaceCanvas from '../_app/cuchillo/layout/InterfaceCanvas';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';
import BezierEasing from 'bezier-easing'
import { Functions } from '../_app/cuchillo/utils/Functions';
import Hud from './Hud';

export default class GraphCircles {
  static canvas = InterfaceCanvas.canvas || document.createElement('canvas');
  static ctx = InterfaceCanvas.ctx || this.canvas.getContext('2d');
  static dom;
  static x;
  static y;
  static width = 0;
  static height = 0;
  static circles;
  
  static limits = {x0:0,y0:0,x1:0,y1:0};
  static time = 0;
  static speed = 0;

  static ellipseSize = {width:100, height:50}
  
  
  static init(__dom) {
    this.dom = __dom;
    this.resize();

    this.circles = new Array(2);
   
    for(let i=0, j=this.circles.length; i<j; i++) {
      const direction1 = Maths.maxminRandom(100,0)>50?1:-1;
      const direction2 = Maths.maxminRandom(100,0)>50?1:-1;
      this.circles[i] = {
        rotation: Maths.toRadians(Maths.maxminRandom(360,0)),
        size: Maths.maxminRandom(this.limits.size1,this.limits.size0),
        speedRotation: Maths.maxminRandom(50,20)/1000,
        speedSize: Maths.maxminRandom(100,50)/100,
        rotationMod: 0,
        sizeMod: 0,
      }     
    }
  }

  static update() {
    this.circles[0].rotationMod = Maths.lerp(0, .2, Interaction.positions.mouse.y/Metrics.HEIGHT);
    this.circles[1].sizeMod = Maths.lerp(0, 2, Interaction.positions.mouse.x/Metrics.WIDTH);
    
    this.circles[0].size = Maths.lerp(this.limits.size0, this.limits.size1, Interaction.positions.mouse.y/Metrics.HEIGHT);
    this.circles[1].rotation = Maths.lerp(this.limits.rad1, this.limits.rad0, Interaction.positions.mouse.x/Metrics.WIDTH);

    this.circles[0].rotation -= this.circles[0].speedRotation + this.circles[0].rotationMod;
    if(this.circles[0].rotation < this.limits.rad0) {
      this.circles[0].rotation = this.limits.rad1 + this.circles[0].rotation;
    } else if(this.circles[0].rotation > this.limits.rad1) {
      this.circles[0].rotation = this.limits.rad0 + (this.circles[0].rotation - this.limits.rad1);
    } 

    this.circles[1].size -= this.circles[1].speedSize + this.circles[1].sizeMod;
    if(this.circles[1].size < this.limits.size0) {
      this.circles[1].size = this.limits.size1 + (this.circles[1].size + this.limits.size1);
    } else if(this.circles[1].size > this.limits.size1) {
      this.circles[1].size = this.limits.size0 + (this.circles[1].size - this.limits.size1);
    }


    for(let i=0, j=this.circles.length; i<j; i++) {
      
    }      
  }

  static loop() {
    this.time++;

    const drawCircle = (width, size, rotation) => {
      this.ctx.beginPath();
      this.ctx.ellipse(this.ellipseSize.x, this.ellipseSize.y, width, Math.abs(size), rotation, 0, Math.PI * 2);  
      this.ctx.strokeStyle = "#FEEE08";
      this.ctx.stroke();
      this.ctx.closePath();
    }


    //box 
    drawCircle(this.ellipseSize.width,this.ellipseSize.height,0);
    drawCircle(this.ellipseSize.width,this.circles[0].size,this.circles[0].rotation);
    drawCircle(this.ellipseSize.width,this.circles[1].size,this.circles[1].rotation);
  }

  static resize() {
    const size = this.dom.getBoundingClientRect(); 

    this.x = size.x * Sizes.RATIO_HUD;
    this.y = size.y * Sizes.RATIO_HUD;
    this.width = size.width * Sizes.RATIO_HUD;
    this.height = size.height * Sizes.RATIO_HUD;

    this.ellipseSize = {
      x: this.x + this.width / 2,
      y: this.y + this.height / 2,
      width: this.width / 2,
      height: this.height / 2
    }

    this.limits = {
      rad0: Maths.toRadians(0),
      rad1: Maths.toRadians(360),
      size0: -this.ellipseSize.width,
      size1: this.ellipseSize.width
    }
  }
}

