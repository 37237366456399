import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import InterfaceCanvas from '../_app/cuchillo/layout/InterfaceCanvas';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Sizes } from '../_app/cuchillo/core/Sizes';
import Hud from './Hud';

export default class GlobeRadarHud {
  static canvas = InterfaceCanvas.canvas || document.createElement('canvas');
  static ctx = InterfaceCanvas.ctx || this.canvas.getContext('2d');
  static x;
  static y;
  static width = 0;
  static height = 0;
  static items = [];
  static limits = {y0:0,y1:0};
  static old = [0,0];  
  static time = 0;
  static marker = {
    size:0,
    offset:0
  };
  static old = {
    x:0,
    y:0
  }
    
  static init(__dom) {
    this.dom = __dom;
    this.resize();

    //setup steps
    for(let i=0;i<6;i++) {
      this.items.push({
        x:0
      });
    }
  }

  static loop() {
    this.time++;

    const drawBox = (x,y,color) => {
      this.ctx.beginPath();
      this.ctx.rect(this.x+x+this.marker.offset, this.y+y+this.marker.offset, this.marker.size, this.marker.size);
      this.ctx.strokeStyle = color;
      this.ctx.stroke();
      this.ctx.closePath();
    }

    const drawRect = (x,y,color) => {
      this.ctx.beginPath();
      this.ctx.rect(this.x+x+this.marker.offset, this.y+y+this.marker.offset, this.marker.size, this.marker.size);
      this.ctx.fillStyle = color;
      this.ctx.fill();
      this.ctx.closePath();
    }

  
    const modX = Math.round(Maths.lerp(0, this.limits.cols, Interaction.positions.mouse.y/Metrics.HEIGHT)) * Hud.grid;
    const modY = Math.round(Maths.lerp(0, this.limits.rows, Interaction.positions.mouse.x/Metrics.WIDTH)) * Hud.grid;

    const modXCorners = Math.round(Maths.lerp(0, 2, Interaction.positions.mouse.y/Metrics.HEIGHT)) * Hud.grid;
    const modYCorners = Math.round(Maths.lerp(0, 3, Interaction.positions.mouse.x/Metrics.WIDTH)) * Hud.grid;

    const modXCenter = Math.round(Maths.lerp(-2, 2, Interaction.positions.mouse.x/Metrics.WIDTH)) * Hud.grid;
    const modYCenter = Math.round(Maths.lerp(-2, 2, Interaction.positions.mouse.y/Metrics.HEIGHT)) * Hud.grid;

    this.ctx.globalAlpha = .6;

    //BOX
    //00
    drawBox(0 + modYCorners, 0 + modXCorners, "#FEEE08");
    //01
    drawBox(this.limits.cols * Hud.grid - modYCorners, 0 + modXCorners, "#FEEE08");
    //10
    drawBox(0 + modYCorners, this.limits.rows * Hud.grid - modXCorners, "#FEEE08");
    //11
    drawBox(this.limits.cols * Hud.grid - modYCorners, this.limits.rows * Hud.grid - modXCorners, "#FEEE08");

    //00
    drawRect(0 + modX, 0 + modY, "#FEEE08");
    //01
    drawRect(this.limits.cols * Hud.grid - modX, 0 + modY, "#FEEE08");
    //10
    drawRect(0 + modX, this.limits.rows * Hud.grid - modY, "#FEEE08");
    //11
    drawRect(this.limits.cols * Hud.grid - modX, this.limits.rows * Hud.grid - modY, "#FEEE08");


    drawRect(this.limits.centerX * Hud.grid + modXCenter, this.limits.centerY * Hud.grid + modYCenter, "#FEEE08");
    drawRect(this.limits.centerX * Hud.grid - modXCenter, this.limits.centerY * Hud.grid - modYCenter, "#FEEE08");


    this.ctx.globalAlpha = 1;
  }

  static update() {
    this.old = {
      x1:Maths.maxminRandom(3,0) * Hud.grid,
      x2:Maths.maxminRandom(3,0) * Hud.grid,
      x3:Maths.maxminRandom(3,0) * Hud.grid,
      x4:Maths.maxminRandom(3,0) * Hud.grid
    }
  }

 
  static resize() {
    const size = this.dom.getBoundingClientRect(); 

    this.x = size.x * Sizes.RATIO_HUD;
    this.y = size.y * Sizes.RATIO_HUD;
    this.width = size.width * Sizes.RATIO_HUD;
    this.height = size.height * Sizes.RATIO_HUD;

    this.limits = {
      cols: this.width / Hud.grid - 1,
      rows: this.height / Hud.grid - 1,
      centerX: Math.round((this.width / Hud.grid - 1)/2),
      centerY: Math.round((this.height / Hud.grid - 1)/2)
    }
 
    this.marker = {
      size: Hud.grid * .8,
      offset: Hud.grid * .1
    }

    this.loop();
  }
}

