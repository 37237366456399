import { C, GetBy } from "../_app/cuchillo/core/Element";
import { Keyboard } from '../_app/cuchillo/core/Keyboard';
import StoryTeller from "./StoryTeller";

export default class HudConsole {
  maxCharacters = 52;
  maxCharsAnim = 1000;
  dom;
  domActual;
  domProgress;
  x;
  y;
  width;
  height;
  actual = 0;
  total = 0;
  steps = 0;
  nChar = 0;
  text = "";
  tags = {};
  hasInput = false;
  input = null;

  idH2 = "Š";
  idH3 = "█";
  idP = "▒";
  idBlock = "║";
  idDiv = "░"
  idSpan = "▪"
  idArt = "ỳ"
  idLink = "‰"
  idInsideLink = "ǻ"
  idBackLink = "Ț"
  idProgress = "¤"

  isOpen = true;
  isArt = false;

  btnToggle;
  btnClear;

  strSeparador = "····················································";
  strProgress = "___________________";
  callComplete;

  _urls = [];
  
  constructor(__dom, __domControls) {
    this.dom = __dom;
    this.domControls = __domControls;
    this.tags[this.idH2] = "H2";
    this.tags[this.idH3] = "H3";
    this.tags[this.idP] = "P";
    this.tags[this.idDiv] = "DIV";
    this.tags[this.idSpan] = "SPAN";
    this.tags[this.idArt] = "SPAN";
    this.tags[this.idProgress] = "SPAN";
    this.tags[this.idBlock] = "BLOCKQUOTE";
    this.tags[this.idLink] = "A";
    this.tags[this.idInsideLink] = "AA";
    this.tags[this.idBackLink] = "AAA";

    if(this.domControls) {
      this.setupControls();
    }

    if(this.hasInput) {
      this.setupInput();
    }
  }

  setupInput() {
    Keyboard.add(Keyboard.ALL, "hudConsole", e => this.addKey(e.key));
    Keyboard.add(Keyboard.ENTER, "hudConsoleEnter", e => StoryTeller.check(this.input.textContent));
    Keyboard.add(Keyboard.BACKSPACE, "hudConsoleBack", e => this.delKey(e.key));
  }

  addKey(__key) {
    if(__key.length > 1 || !this.input) return;
    if(new RegExp("[qwertyuiop+asdfghjklñçzxcvbnm,.-QWERTYUIOPASDFGHJKLÑZXCVBNM1234567890 ]").test(__key)) {
      this.input.textContent += __key;
    }
  }

  delKey(__key) {
    this.input.textContent = this.input.textContent.slice(0, this.input.textContent.length - 1);
  }

  addInput() {
    if(!this.input) {
      this.input = document.createElement("SPAN");
      this.input.className = "--input";
      this.dom.appendChild(this.input);
    }
  }

  delInput() {
    if(this.input) {
      this.input = null;
    }
  }

  clearInput() {
    if(this.input) {
      this.input.textContent = "";
    }
  }

  setupControls() {
    this.btnClear = GetBy.class("__clear", this.domControls)[0];
    this.btnClear.addEventListener("click", e => this.clear());

    this.btnToggle = GetBy.class("__toggle", this.domControls)[0];
    this.btnToggle.addEventListener("click", e => this.toggle());
  }

  toggle(__isOpen = !this.isOpen) {
    this.isOpen = __isOpen;
    this.dom.setAttribute("aria-expanded", this.isOpen);

    if(this.isOpen && this.domControls) {
      this.setStateInfo(false);
    }
  }

  setStateInfo(__hasInfo) {
    if(!this.domControls) return;
    if(__hasInfo && !this.isOpen) {
      this.btnToggle.classList.add("--advise");
    } else {
      this.btnToggle.classList.remove("--advise");
    }
  }
  
  art(__s) {
    __s = __s.replace(/(\r\n|\n|\r)/gm, "");
    this.text += __s != ""? this.idArt + __s : "";
    this.total = this.text.length; 
    this.setStateInfo(true);
  }

  progressbar(__progress, __label) {
    const nProgress = Math.round(this.strProgress.length * __progress);
    const strNumProgress = `${Math.round(100*__progress)}`.padStart(3, "0");
    const strBar = "".padStart(nProgress, "=").padEnd(this.strProgress.length, "_");
    const __s = `${__label} [${strBar}] ${strNumProgress}%`
    
    if(this.domProgress) {
      this.domProgress.textContent = __s;
    } else {
      this.text += __s != ""? this.idProgress + __s : "";
      this.total = this.text.length; 
      this.setStateInfo(true);
    }
  }

  link(__s, __url = null) {
    this.text += __s.trim() != ""? this.idLink + __s : "";
    this.total = this.text.length; 
    this.setStateInfo(true);
    this._urls.push(__url? __url : __s);
  }

  insideLink(__s, __url = null) {
    this.text += __s.trim() != ""? this.idInsideLink + __s : "";
    this.total = this.text.length; 
    this.setStateInfo(true);
    this._urls.push(__url? __url : __s);
  }

  backLink(__s, __url = null) {
    this.text += __s.trim() != ""? this.idBackLink + __s : "";
    this.total = this.text.length; 
    this.setStateInfo(true);
    this._urls.push(__url? __url : __s);
  }

  log(__s, hasMargin = true) {
    const idTag = hasMargin? this.idP : this.idDiv;
    this.text += __s.trim() != ""? idTag + __s : "";
    this.total = this.text.length; 
    this.setStateInfo(true);
  }

  h2(__s) {
    this.text += __s.trim() != ""? this.idH2 + __s : "";
    this.total = this.text.length; 
    this.setStateInfo(true);
  }

  h3(__s) {
    this.text += __s.trim() != ""? this.idH3 + __s : "";
    this.total = this.text.length; 
    this.setStateInfo(true);
  }

  block(__s) {
    if(__s.trim()!="") {
      this.text += this.idBlock  + this.strSeparador;
      const n1 = Math.max(0, Math.floor((this.strSeparador.length - __s.length)/2));
      const n2 = Math.max(0, Math.ceil((this.strSeparador.length - __s.length)/2));
      this.text += __s.padStart(n1 + __s.length, "-").padEnd(n1 + n2 + __s.length, "-") + this.strSeparador;
      
      this.setStateInfo(true);
    }
  }
  
  separator() {
    if(this.text != "") {
      this.art(this.strSeparador);
      this.art(this.strSeparador);
    }
  }

  clear() {
    this.text = "";
    this.total = 0;
    this.actual = 0;
    this._urls = [];
    this.domProgress = null;
    C.empty(this.dom);
  }

  loop() {
    if(this.actual < this.total) {
      this.delInput();
      
      if(this.isArt) {
        this.nChar++;
        if(this.nChar > this.maxCharacters - 1) {
          this.domActual = document.createElement(this.tags[this.idArt]);
          this.dom.appendChild(this.domActual);
          this.nChar = 0;
        }
      }

      if(this.tags[this.text[this.actual]]) {
        
        const tagId = this.text[this.actual]; 
        
        let tag = this.tags[tagId] === "AA" || this.tags[tagId] === "AAA"? "A" : this.tags[tagId];
        
        this.isArt = tagId === this.idArt;
        this.domActual = document.createElement(tag);
        this.dom.appendChild(this.domActual);
        this.actual++;
        this.nChar = 0;
        
        if(tagId === this.idProgress) {
          this.domProgress = this.domActual;
        }

        if(this.tags[tagId] === "A" || this.tags[tagId] === "AA" || this.tags[tagId] === "AAA") {
          const target = this.tags[tagId] === "AA" || this.tags[tagId] === "AAA"? "_self" : "_blank";

          if(this.tags[tagId] === "AAA") {
            this.domActual.setAttribute("data-back", true);  
          }
          this.domActual.setAttribute("href", this._urls.shift());
          this.domActual.setAttribute("target", target);
        }
      }

      const n = Math.min(this.maxCharsAnim, Math.floor((this.text.length - this.actual)/4));
      this.domActual.textContent += this.text.charAt(this.actual);
      this.actual++; 
   
      if(this.steps < n) {
        this.steps++;
        this.loop();
      } else {
        this.steps = 0;
      }
    } else {
      this.steps = 0;
      if(this.hasInput) {
        this.addInput();
      }
      if(this.callComplete) {
        this.callComplete();
        this.callComplete = null;
      }
    }
  }

  resize() {
    const size = this.dom.getBoundingClientRect(); 

    this.x = size.x;
    this.y = size.y;
    this.width = size.width;
    this.height = size.height;
  }
}

