import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Sizes } from '../_app/cuchillo/core/Sizes';
import InterfaceCanvas from '../_app/cuchillo/layout/InterfaceCanvas';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';
import BezierEasing from 'bezier-easing'

export default class ScrollHud {
  static canvas = InterfaceCanvas.canvas || document.createElement('canvas');
  static ctx = InterfaceCanvas.ctx || this.canvas.getContext('2d');
  static dom;
  static x;
  static y;
  static markerSize = 0;
  static width = 0;
  static height = 0;
  static progress = 0;  
  static offset = 0;
  static lines = new Array(10);
  static limits = {x0:0,y0:0,x1:0,y1:0};
  static time = 0;
  static easing = BezierEasing(0.455, 0.030, 0.515, 0.955);
  
  
  static init(__dom) {
    this.dom = __dom;
    this.resize();

    this.offset = 1/this.lines.length;
    for(let i=0, j=this.lines.length; i<j; i++) {
      this.lines[i] = this.offset * i;
    }
    

    
  }

  static loop() {
    this.time++;
      
    //LINE
    const smoothstep = (min, max, value) => {
      var x = Math.max(0, Math.min(1, (value-min)/(max-min)));
      var y=2*x*x;
      if(x>0.5){
          x-=1;
          y=-2*x*x+1;
      }
      return y;
    };

    const drawLine = (x) => {
      this.ctx.beginPath();
      this.ctx.moveTo(x, this.limits.y0);
      this.ctx.lineTo(x, this.limits.y1);
      this.ctx.strokeStyle = "#FEEE08";
      this.ctx.stroke();
      this.ctx.closePath();
    }

    const drawMarker = (x, y) => {
      const size = this.markerSize*.5;

      this.ctx.beginPath();
      this.ctx.moveTo(x, y);
      this.ctx.lineTo(x - size, y + size);
      this.ctx.lineTo(x, y + size * 2);
      this.ctx.lineTo(x + size, y + size);
      this.ctx.lineTo(x, y);

      this.ctx.strokeStyle = "#FEEE08";
      this.ctx.stroke();
      this.ctx.closePath();
    }

    //box
    drawLine(this.limits.x0);
    drawLine(this.limits.x1);

    const val = this.progress - this.lines[0]; 

    for(let i=0, j=this.lines.length; i<j; i++) {
      this.lines[i] += val;
      if(this.lines[i] > 1) {
        this.lines[i] = this.lines[i] - 1;
      } if(this.lines[i] < 0) {
        this.lines[i] = 1 + this.lines[i];
      }

      const x = Maths.lerp(this.limits.x0, this.limits.x1, this.easing(this.lines[i])) ;
      drawLine(x);

      if(i===0) {
        //drawMarker(x,this.limits.y1 + 10 * Sizes.RATIO_HUD);
      }
    }

    drawMarker(Maths.lerp(this.limits.x0, this.limits.x1, this.progress),this.limits.y1 + 10 * Sizes.RATIO_HUD);
  }

  static resize() {
    const size = this.dom.getBoundingClientRect(); 
    this.x = size.x * Sizes.RATIO_HUD;
    this.y = (size.y + Metrics.parseSize("5fpx")) * Sizes.RATIO_HUD;
    this.width = size.width * Sizes.RATIO_HUD;
    this.height = size.height * Sizes.RATIO_HUD * .5;

    this.markerSize = Metrics.parseSize("10fpx") * Sizes.RATIO_HUD

    this.limits = {
      x0: this.x,
      y0: this.y,
      x1: this.x + this.width,
      y1: this.y + this.height
    }

    this.loop();
  }
}

