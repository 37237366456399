import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import InterfaceCanvas from '../_app/cuchillo/layout/InterfaceCanvas';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Sizes } from '../_app/cuchillo/core/Sizes';
import Hud from './Hud';

export default class GraphRadar {
  static canvas = InterfaceCanvas.canvas || document.createElement('canvas');
  static ctx = InterfaceCanvas.ctx || this.canvas.getContext('2d');
  static x;
  static y;
  static width = 0;
  static height = 0;
  static steps = [];
  static limits = {y0:0,y1:0};
  static old = [0,0];  
  static time = 0;
  static marker = {
    size:0,
    offset:0
  };
  static old = {
    x:0,
    y:0
  }
    
  static init(__dom) {
    this.dom = __dom;
    this.resize();

    //setup steps
    for(let i=0;i<4;i++) {
      const scrollVal = Maths.maxminRandom(6, 2)/10;
      const extVal = Maths.maxminRandom(4, 2)/10;
      this.steps.push({
        value:0,
        scrollVal: scrollVal,
        extVal: extVal,
        time: Maths.maxminRandom(1000, 1),
        amplitude: Maths.maxminRandom(100, 5)/10000,
        mouseMod: 1 - scrollVal - extVal
      });
    }
  }

  static loop() {
    this.time++;

    const drawBox = (x,y,color) => {
      this.ctx.beginPath();
      this.ctx.rect(x, y, this.width, this.height);
      this.ctx.strokeStyle = color;
      this.ctx.stroke();
      this.ctx.closePath();
    }

    const drawRect = (x,y,color) => {
      this.ctx.beginPath();
      this.ctx.rect(x+this.marker.offset, y+this.marker.offset, this.marker.size, this.marker.size);
      this.ctx.fillStyle = color;
      this.ctx.fill();
      this.ctx.closePath();
    }

    const posX = Math.round(Maths.lerp(0,3,Interaction.positions.mouse.x/Metrics.WIDTH)) * Hud.grid;
    const posY = Math.round(Maths.lerp(0,3,Interaction.positions.mouse.y/Metrics.HEIGHT)) * Hud.grid;

  
    /*drawRect(this.x + Maths.maxminRandom(0,3) * Hud.grid, this.y + Maths.maxminRandom(0,3) * Hud.grid, "#FEEE08");
    drawRect(this.x + Maths.maxminRandom(0,3) * Hud.grid, this.y + Maths.maxminRandom(0,3) * Hud.grid, "#FEEE08");*/
    this.ctx.globalAlpha = .2;
    drawRect(this.x + this.old.x1, this.y + 0, "#444DBC");
    drawRect(this.x + this.old.x2, this.y + Hud.grid, "#444DBC");
    drawRect(this.x + this.old.x3, this.y + Hud.grid * 2, "#444DBC");
    drawRect(this.x + this.old.x4, this.y + Hud.grid * 3, "#444DBC");
    this.ctx.globalAlpha = 1;

    this.ctx.globalAlpha = .6;
    drawRect(this.x + posX, this.y + posY, "#444DBC");
    this.ctx.globalAlpha = 1;

    drawBox(this.x, this.y, "#FEEE08");
    
    //BOX
    this.ctx.globalAlpha = .1;
    this.drawRadar();
    this.ctx.globalAlpha = 1;
  }

  static update() {
    this.old = {
      x1:Maths.maxminRandom(3,0) * Hud.grid,
      x2:Maths.maxminRandom(3,0) * Hud.grid,
      x3:Maths.maxminRandom(3,0) * Hud.grid,
      x4:Maths.maxminRandom(3,0) * Hud.grid
    }
  }

  static drawRadar() {
    const drawLine = (x0,y0,x1,y1) => {
      this.ctx.beginPath();
      this.ctx.moveTo(x0, y0);
      this.ctx.lineTo(x1, y1);
      this.ctx.strokeStyle = "#FEEE08";
      this.ctx.stroke();
      this.ctx.closePath();
    }

    drawLine(this.limits.x0, this.y + Hud.grid, this.limits.x1, this.y + Hud.grid);
    drawLine(this.limits.x0, this.y + Hud.grid*2, this.limits.x1, this.y + Hud.grid*2);
    drawLine(this.limits.x0, this.y + Hud.grid*3, this.limits.x1, this.y + Hud.grid*3);


    drawLine(this.x + Hud.grid, this.limits.y0, this.x + Hud.grid, this.limits.y1);
    drawLine(this.x + Hud.grid*2, this.limits.y0, this.x + Hud.grid*2, this.limits.y1);
    drawLine(this.x + Hud.grid*3, this.limits.y0, this.x + Hud.grid*3, this.limits.y1);
  }

  static resize() {
    const size = this.dom.getBoundingClientRect(); 

    this.x = size.x * Sizes.RATIO_HUD;
    this.y = size.y * Sizes.RATIO_HUD;
    this.width = size.width * Sizes.RATIO_HUD;
    this.height = size.height * Sizes.RATIO_HUD;

    this.limits = {
      x0: this.x,
      y0: this.y,
      x1: this.x + this.width,
      y1: this.y + this.height
    }


    this.thumbSize = this.width / this.steps.length;
    

    this.marker = {
      size: Hud.grid * .8,
      offset: Hud.grid * .1
    }

    this.loop();
  }
}

