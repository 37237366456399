import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Sizes } from '../_app/cuchillo/core/Sizes';
import InterfaceCanvas from '../_app/cuchillo/layout/InterfaceCanvas';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';
import BezierEasing from 'bezier-easing'
import { Functions } from '../_app/cuchillo/utils/Functions';
import Hud from './Hud';



export default class StoryTeller {
  static commands = {
    "2": () => Scroll.gotoAnchor("faq"),
    "02": () => Scroll.gotoAnchor("faq"),
    "faq": () => Scroll.gotoAnchor("faq"),
    "3": () => Scroll.gotoAnchor("nfts"),
    "03": () => Scroll.gotoAnchor("nfts"),
    "nfts": () => Scroll.gotoAnchor("nfts"),
    "thenfts": () => Scroll.gotoAnchor("nfts"),
    "the nfts": () => Scroll.gotoAnchor("nfts"),
    "the_nfts": () => Scroll.gotoAnchor("nfts"),
    "4": () => Scroll.gotoAnchor("corps"),
    "04": () => Scroll.gotoAnchor("corps"),
    "corps": () => Scroll.gotoAnchor("corps"),
    "corporations": () => Scroll.gotoAnchor("corps"),
    "thecorporations": () => Scroll.gotoAnchor("corps"),
    "the corporations": () => Scroll.gotoAnchor("corps"),
    "the_corporations": () => Scroll.gotoAnchor("corps"),
    "5": () => Scroll.gotoAnchor("roadmap"),
    "05": () => Scroll.gotoAnchor("roadmap"),
    "roadmap": () => Scroll.gotoAnchor("roadmap"),
    "6": () => Scroll.gotoAnchor("team"),
    "06": () => Scroll.gotoAnchor("team"),
    "team": () => Scroll.gotoAnchor("team"),
    "1": () => Scroll.gotoAnchor("about"),
    "01": () => Scroll.gotoAnchor("about"),
    "about": () => Scroll.gotoAnchor("about"),
    "credits": () => StoryTeller.credits(),
    "7": () => StoryTeller.credits(),
    "07": () => StoryTeller.credits(),
    "help": () => StoryTeller.help(),
    "cuchillo": () => StoryTeller.cuchillo(),
    "8": () => StoryTeller.cuchillo(),
    "08": () => StoryTeller.cuchillo(),
    "wen whitelist": () => StoryTeller.whiteRabbit(),
  }

  static check(__command) {
    Hud.info.clearInput();
    const cmd = this.commands[__command.toLowerCase()];
    if(cmd) cmd();
    else if(__command.trim()!="") {
      StoryTeller.help("WARPZONE")
    }
  }

  static whiteRabbit() {
    Hud.info.clear();
    Hud.info.h2("Don’t follow the white rabbit, but a bird dressed in black and white.");
    Hud.info.link("https://form.typeform.com/to/SkyWdR3y")
    Hud.console.h2("Don’t follow the white rabbit, but a bird dressed in black and white.");
    Hud.console.link("https://form.typeform.com/to/SkyWdR3y")
  }

  static help(__title = "COMMANDS") {
    Hud.info.clear();
    Hud.info.h2("\\\\" + __title);
    Hud.info.art("01.ABOUT              02.FAQ               03.NFTS", false);
    Hud.info.art("04.CORPS              05.ROADMAP           06.TEAM", false);
    Hud.info.art("07.CREDITS            08.CUCHILLO", false);
  }

  static credits() {
    Hud.console.h2("fonts");
    Hud.console.log("Supply Mono Light by PangramPangram©",false);
    Hud.console.log("Evangelion by Domenico Barreto",false);
    setTimeout(()=> {
      Hud.console.h2("libs");
      Hud.console.log("ThreeJS by Mr.doob",false);
      Hud.console.log("Gsap by Greenshock",false);
    }, 500);
    setTimeout(()=> {
      Hud.console.h2("tools");
      Hud.console.log("Text to ASCII by patorjk.com",false);
      Hud.console.log("SVGOMG by jakearchibald.github.io",false);
      Hud.console.log("Favicon Generator by realfavicongenerator.net",false);
    }, 1000);
    setTimeout(()=> {
      Hud.console.h2("made_by");
    Hud.console.art(`·········/∅∅∅∅∅∅  /∅∅   /∅∅  /∅∅∅∅∅∅  /∅∅   /∅∅·····
········/∅∅__  ∅∅| ∅∅  | ∅∅ /∅∅__  ∅∅| ∅∅  | ∅∅·····
·······| ∅∅  \\__/| ∅∅  | ∅∅| ∅∅  \\__/| ∅∅  | ∅∅·····
·······| ∅∅      | ∅∅  | ∅∅| ∅∅      | ∅∅∅∅∅∅∅∅·····
·······| ∅∅      | ∅∅  | ∅∅| ∅∅      | ∅∅__  ∅∅·····
·······| ∅∅    ∅∅| ∅∅  | ∅∅| ∅∅    ∅∅| ∅∅  | ∅∅·····
·······|  ∅∅∅∅∅∅/|  ∅∅∅∅∅∅/|  ∅∅∅∅∅∅/| ∅∅  | ∅∅·····
········\\______/  \\______/  \\______/ |__/  |__/·····
········/∅∅∅∅∅∅ /∅∅       /∅∅        /∅∅∅∅∅∅········
·······|_  ∅∅_/| ∅∅      | ∅∅       /∅∅__  ∅∅·······
·········| ∅∅  | ∅∅      | ∅∅      | ∅∅  \\ ∅∅·······
·········| ∅∅  | ∅∅      | ∅∅      | ∅∅  | ∅∅·······
·········| ∅∅  | ∅∅      | ∅∅      | ∅∅  | ∅∅·······
·········| ∅∅  | ∅∅      | ∅∅      | ∅∅  | ∅∅·······
········/∅∅∅∅∅∅| ∅∅∅∅∅∅∅∅| ∅∅∅∅∅∅∅∅|  ∅∅∅∅∅∅/·······
·······|______/|________/|________/ \\______/········`);
    }, 1500);
  }

  static cuchillo() {
    
    Hud.console.art(`·········/∅∅∅∅∅∅  /∅∅   /∅∅  /∅∅∅∅∅∅  /∅∅   /∅∅·····
········/∅∅__  ∅∅| ∅∅  | ∅∅ /∅∅__  ∅∅| ∅∅  | ∅∅·····
·······| ∅∅  \\__/| ∅∅  | ∅∅| ∅∅  \\__/| ∅∅  | ∅∅·····
·······| ∅∅      | ∅∅  | ∅∅| ∅∅      | ∅∅∅∅∅∅∅∅·····
·······| ∅∅      | ∅∅  | ∅∅| ∅∅      | ∅∅__  ∅∅·····
·······| ∅∅    ∅∅| ∅∅  | ∅∅| ∅∅    ∅∅| ∅∅  | ∅∅·····
·······|  ∅∅∅∅∅∅/|  ∅∅∅∅∅∅/|  ∅∅∅∅∅∅/| ∅∅  | ∅∅·····
········\\______/  \\______/  \\______/ |__/  |__/·····
········/∅∅∅∅∅∅ /∅∅       /∅∅        /∅∅∅∅∅∅········
·······|_  ∅∅_/| ∅∅      | ∅∅       /∅∅__  ∅∅·······
·········| ∅∅  | ∅∅      | ∅∅      | ∅∅  \\ ∅∅·······
·········| ∅∅  | ∅∅      | ∅∅      | ∅∅  | ∅∅·······
·········| ∅∅  | ∅∅      | ∅∅      | ∅∅  | ∅∅·······
·········| ∅∅  | ∅∅      | ∅∅      | ∅∅  | ∅∅·······
········/∅∅∅∅∅∅| ∅∅∅∅∅∅∅∅| ∅∅∅∅∅∅∅∅|  ∅∅∅∅∅∅/·······
·······|______/|________/|________/ \\______/········`);
Hud.console.h2("team");
Hud.console.link("https://www.instagram.com/joderirene");
Hud.console.link("https://www.instagram.com/adrian_vision");
Hud.console.link("https://www.instagram.com/_borderia");
Hud.console.link("https://www.instagram.com/maarten_volckaert");
Hud.console.link("https://twitter.com/josesentis_");
Hud.console.link("https://www.instagram.com/mal.studio");
Hud.console.link("https://www.instagram.com/rober_cuchillo");
Hud.console.link("https://twitter.com/mr__corrales",);






    
  }
}