export default `
    uniform float progress;
    uniform float alpha;
    uniform sampler2D texture1;
    uniform sampler2D texture2;
    uniform sampler2D texture3;
    uniform vec4 resolutionX;
    uniform vec4 resolutionY;
    varying vec2 vUv;

    void main() {
        vec2 newUVX = (vUv - vec2(progress, 1.0 - progress)) * resolutionX.xy + vec2(progress, 1.0 - progress);
        vec2 newUVY = (vUv - vec2(0.5, 1.0 - progress)) * resolutionY.xy + vec2(0.5, 1.0 - progress);

        vec4 color1 = texture2D(texture1, newUVY);
        vec4 color2 = texture2D(texture2, newUVX);
        vec4 color3 = texture2D(texture3, newUVY);

        vec3 mix1 = mix(color1.rgb, color2.rgb, color2.a);
        vec3 mix2 = mix(mix1, color3.rgb, color3.a);

        gl_FragColor = vec4(mix2.rgb, alpha);
    }
`;
