import { CSS } from '../_app/cuchillo/utils/CSS';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Metrics } from '../_app/cuchillo/core/Metrics';

class VTeam {
  static selector = "data-scroll-team"
  static isNativeAllowed = true;

  item;
  scale0;
  scale1;
  offset;
  x;
  y;
  z;

  constructor(_item) {
    this.item = _item;

    this.scale1 = this.item.getAttribute("data-end")!== null? Number(this.item.getAttribute("data-end")) : 1;
    this.scale0 = this.item.getAttribute("data-start")!== null? Number(this.item.getAttribute("data-start")) : 2;

    this.offset = this.item.offsetLeft;

    const TRANSLATE = CSS.getTranslate(this.item);
    this.x = TRANSLATE.x;
    this.y = TRANSLATE.y;
    this.z = this.item.style.zIndex || 0;
  }

  loop(__position, __progress, __progressInside) {

    //console.log("Progres -> " + __progress, __progressInside)


    
    const POS = this.axis === "x"? __position.x : __position.y;
    const PROGRESS = Maths.normalize(0, this.width, POS);
    const ALPHA = Maths.normalize(0, -this.width, POS);

    const p = Math.max(0, Maths.normalize(1, .5, __progress));

    let x = (this.offset + __position.x);
    

    if(__progress > .5) {
      
     
      const rot = Math.min(10, (10 * p));
  
      const x = __position.x + this.offset;
      this.item.style[CSS.transform] = CSS.rotate3D(0, 1, 0, rot) + " " + CSS.translate3D((Metrics.WIDTH * .2) * p, this.y, this.z) + " " + CSS.scale3D(1, 1);
    

      //this.item.style[CSS.transform] = CSS.translate3D(__position.x * 1, this.y, this.z) + " " + CSS.scale3D(1, 1);
    } else {
      this.item.style[CSS.transform] = CSS.rotate3D(0, 0, 0, 0) + " " + CSS.translate3D(this.x, this.y, this.z) + " " + CSS.scale3D(1, 1);
    }  
  }

  dispose(){};

  resize(__size) {}
}

Scroll._registerInsider(VTeam);

