import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from "../_app/cuchillo/scroll/VScroll_Item";
import { Shffl } from '../components/Shffl';
import { GetBy } from '../_app/cuchillo/core/Element';
import Hud from '../hud/Hud';
import HudConsole from '../hud/HudConsole';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Basics } from '../_app/cuchillo/core/Basics';
import { ControllerWindow } from '../_app/cuchillo/windows/ControllerWindow';

export default class ScrollItem__HeaderArticle extends VScroll_Item {
  title;
  text;
  hudLink;
  consoleText = "";
  consoleArt = "";
  isNeedClearInfo = true;
  hasTitleConsole = true;
  idTimerArt = 0;
  win;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.title = GetBy.class("__title", this.item)[0]? GetBy.class("__title", this.item)[0].textContent : ""; 
    this.links = [...GetBy.class("__link", this.item)];
    this.onShow = () => { this.showInfo() };
  }

  showInfo() {
    Hud.info.clear();
    Hud.info.h2("\\\\" + this.title);
    Hud.console.clear();
    Hud.info.hasInput = false;

    this.win = ControllerWindow.getWindow("win-"+this.item.getAttribute("data-id"));
    if(this.win) {
      if(!this.win.isOpen) {
        ControllerWindow.hideAll();

        GetBy.class("__btn-universe_categories")[0].style.display = "none";
        GetBy.class("__btn-archive_categories")[0].style.display = "none";
        GetBy.class("__btn-" + this.item.getAttribute("data-id"))[0].style.display = "block";

        this.win.show();
      }
    } else {
      ControllerWindow.hideAll();

      GetBy.class("__btn-universe_categories")[0].style.display = "none";
      GetBy.class("__btn-archive_categories")[0].style.display = "none";
    }

  
    this.links.map(item => {
  /*    if(item.getAttribute("data-back") != "true") {
        Hud.info.insideLink(item.textContent, item.href);  
      } else { */
       
        Hud.info.backLink(item.textContent, item.href);  
      //}
    });

    
  }
}

Scroll._registerClass('HeaderArticle', ScrollItem__HeaderArticle);
