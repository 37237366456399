import gsap from "gsap";

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import CorporationsScene from '../3D/CorporationsScene';
import Hud from '../hud/Hud';
import ScrollItem__Default from "./ScrollItem__Default";

class ScrollItem__Corporations extends ScrollItem__Default {
  _slider;
  _call;
  _scene;
  _idTimerResize;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._scene = new CorporationsScene(__link);

    this.onShow = () => {
      this.showInfo();
      this._scene.start();
    };

    this.onHide = () => {
      this._scene.update(-1);
      this._scene.stop();
    };

    this.onMove = (__position) => {
      this._scene.update(1 - this.progressInside);
    }
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  show () {
    super.show();
  }

  hide () {
    super.hide();
  }

  loop () {
    
  }

  dispose () {
    gsap.ticker.remove(this._call);
    
    super.dispose();
  }

  resize (__w, __h) {
    super.resize(__w, __h)

    clearTimeout(this._idTimerResize);
    this._idTimerResize = setTimeout(()=> {this._scene.resize()},500);
  }
}

Scroll._registerClass('slider-corporations', ScrollItem__Corporations);
