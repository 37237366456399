import * as THREE from 'three';
import gsap from 'gsap';

import { Metrics } from '../_app/cuchillo/core/Metrics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import Corporation from './Corporation';

export default class CorporationsScene {
  _container;
  _scene;
  _renderer;
  _camera;
  _items = [];
  _gui;
  _progress = 0;
  _width;
  _height;
  _cameraDistance = 500;

  constructor(container) {
    this._container = container;
    this._width = Metrics.WIDTH;
    this._height = Metrics.HEIGHT;

    this._scene = new THREE.Scene();

    this.initRenderer();
    this.initLights();
    this.initCamera();

    const items = Array.from(GetBy.selector('#slider-corporations .__corporation'));
    items.map(item => {
      this._items.push(new Corporation(this._scene, item));
    });

    this._call = () => { this.loop(); }

    this.resize();
  }

  start () {
    gsap.ticker.add(this._call);
    window.addEventListener('resize', this.resize.bind(this));
  }

  stop () {
    gsap.ticker.remove(this._call);
    this.loop();
    //window.removeEventListener('resize', this.resize.bind(this));
  }

  initRenderer () {
    this._renderer = new THREE.WebGLRenderer({
      antialias: false,
      alpha: true
    });
    this._renderer.setPixelRatio(1.4);
    this._renderer.setSize(this._width, this._height);
    this._renderer.sortObjects = false;
    this._renderer.outputEncoding = THREE.sRGBEncoding;

    this._renderer.domElement.id = "Corporation__Canvas";
    document.body.appendChild(this._renderer.domElement);
  }

  initLights () {
    const ambientlight = new THREE.AmbientLight(0xffffff, 2);
    this._scene.add(ambientlight);
  }

  initCamera () {
    this._camera = new THREE.PerspectiveCamera(60, Metrics.WIDTH / Metrics.HEIGHT, 1, 1000);
    this._camera.position.set(0, 0, this._cameraDistance);

    this.camera = new THREE.PerspectiveCamera(
      70,
      this._width / this._height,
      10,
      10000
    );

    this.camera.position.set(0, 0, this._cameraDistance);
    this.camera.lookAt(0, 0, 0);
  }

  loop () {
    if (this._renderer === undefined || this._scene === undefined || this._camera === undefined) return;

    const TOTAL_SLIDES = this._items.length;
    const PARTIAL_LIMIT = 1 / TOTAL_SLIDES;

    this._items.map((item, index) => {
      const partialProgress = Maths.normalize(PARTIAL_LIMIT * (index + 1), PARTIAL_LIMIT * index, this._progress);
      const alpha = Math.min(Maths.normalize(.0, -.1, partialProgress), Maths.normalize(.9, 1, partialProgress));

      item._material.uniforms.progress.value = Maths.lerp(0, 1, partialProgress);
      item._material.uniforms.alpha.value = alpha;

      if (alpha > 0.5 && !item.visible) {
        item.visible = true;
        item.log();
      } else if (alpha <= 0.5 && item.visible) {
        item.visible = false;
      }
    });

    this._renderer.render(this._scene, this._camera);
  }

  resize () {
    this._width = Metrics.WIDTH;
    this._height = Metrics.HEIGHT;

    this._renderer.setSize(this._width, this._height);
    this._camera.aspect = this._width / this._height;

    this._camera.fov =
      2 * Math.atan(this._width / this.camera.aspect / (2 * this._cameraDistance)) * (180 / Math.PI); // in degrees

    this._camera.updateProjectionMatrix();

    this._items.map(item => {
      item.resize();
    });
  }

  update (progress) {
    this._progress = progress;
  }
}


