import MediaObject from './MediaObject';

export default class VideoObject extends MediaObject {
  constructor(__item) {
      super(__item, MediaObject.TYPE_IMG);
  }

  load(__callback) {
    
    const playPromise = this.item.play();

    if (playPromise !== undefined) {
      playPromise.then(_ => {
        this.item.pause();
        this.setup();
        this.show();
        if(__callback != null)  __callback();
      }).catch(error => {
          // Auto-play was prevented
          // Show paused UI.
          this.item.setAttribute("controls", "true");
          this.setup();
          this.show();
          if(__callback != null)  __callback();
      });
    }
  }

  dispose() {
      if(!super.isStatic) {
        this.item = null;
      }
  }
}


