import { Shffl } from "../components/Shffl";
import { GetBy } from "../_app/cuchillo/core/Element";
import { Metrics } from "../_app/cuchillo/core/Metrics";
import { Sizes } from "../_app/cuchillo/core/Sizes";
import InterfaceCanvas from "../_app/cuchillo/layout/InterfaceCanvas";
import { Scroll } from "../_app/cuchillo/scroll/Scroll";
import GlobeHud from "./GlobeHud";
import GlobeRadarHud from "./GlobeRadarHud";
import GraphCircles from "./GraphCircles";
import GraphRadar from "./GraphRadar";
import GraphSteps from "./GraphSteps";
import HudBG from "./HudBG";
import HudConsole from "./HudConsole";
import HudLogo from "./HudLogo";
import ScrollHud from "./ScrollHud";
import { gsap, Power4 } from 'gsap';
import { isSmartphone } from "../_app/cuchillo/core/Basics";

class HudLink {
  canvas = InterfaceCanvas.canvas || document.createElement('canvas');
  ctx = InterfaceCanvas.ctx || this.canvas.getContext('2d');
  dom;
  x;
  y;
  width;
  height;
  isOver = false;
  isLine;
  cornerSize;

  constructor(__dom) {
    this.dom = __dom;
    this.resize();

    this.isLine = __dom.getAttribute("data-line") != undefined;

    __dom.addEventListener("mouseover", ()=> this.isOver = true);
    __dom.addEventListener("mouseout", ()=> this.isOver = false);
  }

  loop(isClear) {
   
    if(this.dom.classList.contains("--advise")) {
      this.drawRect(this.x, this.y, this.isOver? "#FFFFFF" : "#FEEE08", isClear? .8 : .1, false);  
    } else {
      this.drawRect(this.x, this.y, this.isOver? "#FFFFFF" : "#FEEE08", isClear? .8 : .1, this.isLine);
    }
  }

  drawRect(x,y,color,alpha=.2, isLine) {
    this.ctx.globalAlpha = alpha;
    this.ctx.beginPath();

    this.ctx.moveTo(x,y);
    this.ctx.lineTo(x,y + this.height - this.cornerSize);
    this.ctx.lineTo(x + this.cornerSize, y + this.height);
    this.ctx.lineTo(x + this.width, y + this.height);
    this.ctx.lineTo(x + this.width, y);
    this.ctx.lineTo(x, y);

    if(!isLine || this.isOver) {
      this.ctx.fillStyle = color;
      this.ctx.fill();
    } else {
      this.ctx.strokeStyle = color;
      this.ctx.stroke();
    }
    this.ctx.closePath();
    this.ctx.globalAlpha = 1;
  }


  resize() {
    const size = this.dom.getBoundingClientRect(); 

    this.x = size.x * Sizes.RATIO_HUD;
    this.y = size.y * Sizes.RATIO_HUD;
    this.width = size.width * Sizes.RATIO_HUD;
    this.height = size.height * Sizes.RATIO_HUD;

    this.cornerSize = Math.min(this.height * .25, Hud.grid);
  }
}

export default class Hud {
  
  static domContainer = document.getElementById("Interface");
  static domHeader = document.getElementById("Header");
  static domFooter = document.getElementById("Footer");
  static domControls = document.getElementById("HudAuxTextControls");
  
  static height = 0;
  static tick = 0;
  static grid = 0;
  static direction = -1;
  static info;
  static console;
  static links = [];
  static logo;

  static init() {
    this.info = new HudConsole(document.getElementById("HudMainText"));
    this.console = new HudConsole(document.getElementById("HudAuxText"), document.getElementById("HudAuxTextControls"));
    this.logo = new HudLogo(document.getElementById("HudLogo"), "/assets/images/logo.svg")
    
    Array.from(GetBy.class("__canvasLink")).map(item => this.links.push(new HudLink(item)));

    HudBG.init(document.getElementById("HudBg"),document.getElementById("HudLogo"),document.getElementById("Header"));
    GraphCircles.init(document.getElementById("HudGrapCircles"));
    GraphRadar.init(document.getElementById("HudGrapRadar"));
    ScrollHud.init(document.getElementById("HudScrollbar"));
    GlobeHud.init(document.getElementById("HudGlobe"));
    GlobeRadarHud.init(document.getElementById("HudGlobe"));
    GraphSteps.init(document.getElementById("HudGrapSteps"));

    this.resize();
  }

  static show(__call) {
    this.resize();
    gsap.to([InterfaceCanvas.canvas, this.domHeader, this.domControls, this.domFooter],{
      alpha:1, 
      scaleX:1, 
      scaleY:1, 
      duration:.2,
      ease:Power4.easeIn,
      onComplete:() => {
        this.info.hasInput = true;
        this.info.setupInput();
        if(__call) __call();
      }
    });
  }

  static loop(frame) {
    const isClear = frame === 0;

    if(Scroll.speed!==0) {
      this.direction = Scroll.speed > 0 ? 1 : -1;
    }
    
    const speed = Math.abs(Scroll.speed);
    
    this.tick++;

    if(this.tick%40===0 && !isSmartphone) {
      GraphRadar.update();
      GlobeRadarHud.update();
    }

    if(this.tick%3===0) {
      this.info.loop();
      this.console.loop();
    }

    if(this.tick%5===0) {
      GraphCircles.update();   
      ScrollHud.progress = Scroll.getProgress();
      GlobeHud.speed = Math.max(.01, speed * .0005) * this.direction;
    } else {
      GlobeHud.speed = Scroll.speed * 0;
    }
    
    if(!isSmartphone) {
      GlobeHud.loop();
      GlobeRadarHud.loop();
      GraphSteps.loop();
      GraphRadar.loop();
      GraphCircles.loop();
      this.links.map(item => item.loop(isClear)); 
    }
    HudBG.loop(isClear);
    ScrollHud.loop();
    this.logo.draw();   
  }

  static resize() {
    this.grid = Metrics.parseSize("10fpx");

    this.height = Math.floor((Metrics.HEIGHT - this.grid * 4)/this.grid) * this.grid;
    this.domContainer.style.height = `${this.height}px`;
    
    HudBG.resize();
    ScrollHud.resize();
    if(!isSmartphone) {
      GlobeHud.resize();
      GraphSteps.resize();
      GraphRadar.resize();
      GraphCircles.resize();
      GlobeRadarHud.resize();
      this.links.map(item => item.resize());
    }

    this.info.resize();
    this.console.resize();
    this.logo.resize();
  }
}

