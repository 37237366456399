import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { Maths } from '../_app/cuchillo/utils/Maths';
import ScrollItem__Default from "./ScrollItem__Default";
import { gsap } from "gsap";
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import Hud from '../hud/Hud';
import { Basics, isTouch } from '../_app/cuchillo/core/Basics';
import { Interaction } from '../_app/cuchillo/core/Interaction';

class ScrollItem__Team extends ScrollItem__Default {
  _call;
  actualSlide;
  hasTitleConsole = false;
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.title = GetBy.class("__title", this.item)[0] ? GetBy.class("__title", this.item)[0].textContent : "";
    this.text = GetBy.class("__text", this.item)[0] ? GetBy.class("__text", this.item)[0].textContent : "";
    this.consoleText = GetBy.class("__console", this.item)[0] ? GetBy.class("__console", this.item)[0].textContent : "";
    this.consoleTitle = GetBy.class("__consoleTitle", this.item)[0] ? GetBy.class("__consoleTitle", this.item)[0].textContent : "";

    this._slider = new SliderScroll(__link,
      {
        /*onDragStart:()=> {Cursor.drag = true;},
        onDragEnd:()=> {Cursor.drag = false;},*/
        itemClass: ScrollItem__Team__Item,
        interaction: !isTouch,
        hasScrollbar: true
      });
    //this._progressBar = new ScrollItem__SliderFull_ScrollBar(C.GetBy.class("progress", __link)[0], this._slider);
    //this._progressBar.progress = 0;

    this._call = () => this.loop();
    this.onShow = () => {
      
      gsap.ticker.add(this._call);
      this.showInfo();
      this.gotoActual();
    };

    this.onHide = () => {
      gsap.ticker.remove(this._call);
    };

    // SETUP ITEMS
    this._slider.slides.map(slide => this.setupSlide(slide));
  }

  showInfo() {
    Hud.info.clear();
    if (this.title.trim() != "") Hud.info.h2("\\\\" + this.title);
    Hud.info.log(this.text);

    if (this.title && this.consoleText && this.hasTitleConsole) {
      Hud.console.separator();
      Hud.console.block(this.title);
      Hud.console.separator();
    }

    Hud.console.h3(this.consoleTitle);
    Hud.console.log(this.consoleText);
  }

  gotoActual() {
    if(!this.actualSlide) {
      this.actualSlide = this._slider.slides[1];
    }
    
    this.setActiveSlide(this.actualSlide);
  }

  setupSlide(slide) {
    const dom = slide.item;

    if (dom.classList.contains("void")) return;

    dom.addEventListener(Basics.mouseUp, () => {
      if (Interaction.positions.mouse.x != Interaction.positions.click.x && !isTouch) return;
      this.setActiveSlide(slide);
    });
  }

  setActiveSlide(slide) {
    const dom = slide.item;
    
    const parseText = (__s1, __s2) => {
      return __s1.padEnd(52 - __s2.length, ".") + __s2;;
    }

    const info = {
      name: "\\\\" + GetBy.class("__name", dom)[0].textContent.toUpperCase(),
      role: parseText("[ROLE]", GetBy.class("__role", dom)[0].textContent),
      faction: parseText("[FACTION]", GetBy.class("__faction", dom)[0].textContent),
      link: GetBy.class("__link", dom)[0].textContent,
      bio: GetBy.class("__bio", dom)[0].textContent,
      console: GetBy.class("__console_bio", dom)[0].textContent,
    }

    const percentageTo = (slide.left + slide.width) / this._slider._scroll.p1;
    this._slider.goto_item(slide, true);

    Hud.info.clear();
    Hud.info.h2(info.name);
    Hud.info.art(info.role);
    Hud.info.art(info.faction);
    Hud.info.link(info.link);
    Hud.info.log(info.bio);
    Hud.console.art(info.console);

    const old = GetBy.class("--active", dom.parentNode)[0];
    if (old) {
      old.classList.remove("--active");
    }

    dom.classList.add("--active");

    this.actualSlide = slide;
  }

  loop () {
   
    this._slider.loop();
  }
}

class ScrollItem__Team__Item extends VScroll_Item {

  hasHiddenEnabled = false;
  info;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================

  update () {
    if (this.progress > .5) {
      const p = Math.max(0, Maths.normalize(1, .5, this.progress));
      this._x = this._x + ((Metrics.WIDTH * .5) * p);
    }
    super.update();
  }

  mouseOver () { }
  mouseDown () { }
  mouseUp () { }

  show () {
    super.show();
  }

  hide () {
    super.hide();
  }
}

Scroll._registerClass('Team', ScrollItem__Team);
