import { Scroll } from './_app/cuchillo/scroll/Scroll';
import { VSticky } from './_app/cuchillo/scroll/insiders/VSticky';
import { VScaleZero } from './_app/cuchillo/scroll/insiders/VScaleZero';
import { VScale } from './_app/cuchillo/scroll/insiders/VScale';
import { VInsider } from './_app/cuchillo/scroll/insiders/VInsider';
import { VDisplace } from './_app/cuchillo/scroll/insiders/VDisplace';
import { VInsiderMask } from './_app/cuchillo/scroll/insiders/VInsiderMask';

import { Keyboard } from './_app/cuchillo/core/Keyboard';
import { Basics, isDebug, isMobile, isSmartphone, isTouch } from './_app/cuchillo/core/Basics';
import { Accessibility } from './_app/cuchillo/core/Accessibility';
import { Statics } from './_app/cuchillo/utils/Statics';

import LoaderController from './_app/cuchillo/loaders/LoaderController';
import PagesLoader from './_app/cuchillo/loaders/PagesLoader';
import MediaLoader from './_app/cuchillo/loaders/MediaLoader';
import { ControllerPage } from './_app/cuchillo/pages/ControllerPage';
import { Metrics } from './_app/cuchillo/core/Metrics';
import Default from './pages/Default';
import Article from './pages/Article';
import Legal from './pages/Legal';
import EventDispatcher from './_app/cuchillo/core/EventDispatcher';
import Page from './_app/cuchillo/pages/Page';
import { Interaction, MrInteraction } from './_app/cuchillo/core/Interaction';
import { gsap } from "gsap";
import { ControllerWindow } from './_app/cuchillo/windows/ControllerWindow';
import Win from './_app/cuchillo/windows/Window';

import Wrap from './layout/Wrap';
import InterfaceCanvas from './_app/cuchillo/layout/InterfaceCanvas';
import Loading from './layout/Loading';
import BG from './_app/cuchillo/layout/Background';
import { Colors } from './Colors';
import Cookies from './windows/Cookies';
import Header from './layout/Header';

import { ScrollItem__SliderScrollHorizontal } from './scroll/ScrollItem__SliderScrollHorizontal';
import Guides from './_app/cuchillo/utils/Guides';
import Hud from './hud/Hud';
import { Maths } from './_app/cuchillo/utils/Maths';
import { Sizes } from './_app/cuchillo/core/Sizes';
import { ScrollItem__Default } from './scroll/ScrollItem__Default';
import { ScrollItem__Corporations } from './scroll/ScrollItem__Corporations';
import { ScrollItem__VideoIntro } from './scroll/ScrollItem__VideoIntro';
import { ScrollItem__Team } from './scroll/ScrollItem__Team';
import { ScrollItem__NFTs } from './scroll/ScrollItem__NFTs';
import { ScrollItem__HeaderArticle } from './scroll/ScrollItem__HeaderArticle';
import { VTeam } from './scroll/VTeam';
import { GetBy } from './_app/cuchillo/core/Element';

export default class Main {

  static scrollbar;
  static stats;
  static tick = 0;
  static tickCheck = 20;

  static init () {
    Basics.id = "w11p_v007"; // ID para cookies

    Metrics.init(() => Main.resize()); // Tamaños y resize
    Keyboard.enable(); // ESC para cerrar ventana
    Accessibility.init(); // Utils accesibilidad
    Statics.init(); // Si estamos en debug pinta un FPS counter
    Interaction.init({ ajax: true }) // Posiciones del cursor (Movimiento, click...), Acciones links, drag...
    ControllerWindow.init(); // Control ventanas
    Hud.init();
    BG.init(Colors); // Control de paletas y color de fondo de pantallas. Automatico si añadimos un data-palette='loquesea' en el div con data-page
    InterfaceCanvas.init(); // Canvas de interface, se usa con Cursor
    Cookies.init(); // Checkea y saca el aviso de cookies

    this.doCuchilloInfo();
    this.setWorker();

    // LOOP
    if (isDebug) {
      gsap.ticker.add(() => { Main.loopDebug(); });
    } else {
      gsap.ticker.add(() => { Main.loop(); });
    }

    this.initLoad();
  }

  static initLoad() {
    LoaderController.add(new PagesLoader()); // Carga/Precarga de paginas HTML
    LoaderController.add(new MediaLoader()); // Carga/Precarga de imgs
    LoaderController.onProgress = (p) => { Hud.info.progressbar(p, "\\\\Starting 2112.run"); };
    LoaderController.onComplete = () => Main.loadComplete();
    
    Hud.info.clear();
    Hud.info.callComplete = () => {LoaderController.init();}
    Hud.info.progressbar(0, "\\\\Starting 2112.run");
  }

  static loadComplete() {
    if(isSmartphone) {
      GetBy.class("hud__main-text")[0].style.display = "none";
    }

    Hud.info.clear();
    Hud.info.h2("\\\\2112.run");
    Main.setup();
  }

  static setup () {
    Hud.info.maxCharsAnim = 100;
    this.setupEvents();
    // INIT PAGE
    ControllerPage.init(Wrap.mainholder);
  }

  static setupEvents () {
    EventDispatcher.addEventListener(Win.ON_HIDE, () => { Scroll.setEnabled(true); });
    EventDispatcher.addEventListener(Win.ON_SHOW, () => { Scroll.setEnabled(false); });
  }

  static resize () {
    //  Header.resize();
    InterfaceCanvas.resize(Sizes.RATIO_HUD);
    Hud.resize();
    Guides.resize();
    ControllerPage.resize();
  }

  static loop () {
    // ControllerPage.loop();
    //  Header.loop();
    this.tick++;

    if (this.tick % 3 === 0) {
      InterfaceCanvas.loop();
      this.tick = 0;
      this.tickCheck = Maths.maxminRandom(30, 10);
    }

    Hud.loop(this.tick);

    if (Scroll.isScrolling) Scroll.loop();
  }

  static loopDebug () {
    Statics.begin();
    this.loop();
    Statics.end();
  }

  static doCuchilloInfo () {
    console.log('%cby Cuchillo', 'background: #000; color: #bada55; padding:25px 100px;');
    console.log('⟶ http://cuchillo.studio');
    console.log('⟶ https://www.instagram.com/_cuchillo');
    console.log('⟶ https://twitter.com/somoscuchillo');
    console.log('⟶ https://twitter.com/mr__corrales');
    console.log('');
    console.log('ThreeJS by Mr.doob');
    console.log('⟶ https://threejs.org/');
    console.log('');
    console.log('Gsap by Greenshock');
    console.log('⟶ https://greensock.com');
    console.log('');
    console.log('Font: Supply Mono Light by PangramPangram©');
    console.log('⟶ https://pangrampangram.com/products/supply-mono');
    console.log('');
    console.log('Font: Evangelion by Domenico Barreto');
    console.log('⟶ https://type-department.com/collections/sans-serif-fonts/products/evangelion-font-family/');
    console.log('');
    console.log('Text to ASCII');
    console.log('⟶ https://patorjk.com/');
    console.log('');
    console.log('SVGOMG');
    console.log('⟶ https://jakearchibald.github.io/svgomg/');
    console.log('');
    console.log('Favicon Generator');
    console.log('⟶ https://realfavicongenerator.net');
  }

  static setWorker () {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(function () { });
    }
  }
}

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
  Main.init();
} else {
  document.addEventListener('DOMContentLoaded', Main.init);
}
