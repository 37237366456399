import * as THREE from 'three';

import { GetBy } from '../../_app/cuchillo/core/Element';
import { Metrics } from '../../_app/cuchillo/core/Metrics';
import Hud from '../../hud/Hud';

import FRAGMENT_BASIC from './fragment';
import VERTEX_BASIC from './vertex';

const WIDTH_BACKGROUND = 1728;
const WIDTH_FOREGROUND = 9164;
const HEIGHT = 1200;

export default class Corporation {
  _element;
  _layers = [];
  _loader;
  _sizes;
  _offset;
  _geometry;
  _material;
  _scene;
  _mesh;
  _visible = false;
  consoleText;
  consoleTitle;
  consoleSubTitle;

  constructor(scene, element) {
    this._scene = scene;
    this._element = element;

    this._loader = new THREE.TextureLoader();

    this._layers.push(this._loader.load(this._element.dataset['layer-0']));
    this._layers.push(this._loader.load(this._element.dataset['layer-1']));
    this._layers.push(this._loader.load(this._element.dataset['layer-2']));

    this.consoleText = GetBy.class("__consoleCorp", this._element)[0] ? GetBy.class("__consoleCorp", this._element)[0].textContent : "";
    this.consoleTitle = GetBy.class("__consoleTitleCorp", this._element)[0] ? GetBy.class("__consoleTitleCorp", this._element)[0].textContent : "";
    this.consoleSubTitle = GetBy.class("__consoleSubtitleCorp", this._element)[0] ? GetBy.class("__consoleSubtitleCorp", this._element)[0].textContent : "";

    this._sizes = new THREE.Vector2(0, 0);
    this._offset = new THREE.Vector2(0, 0);

    this.getSizes();
    this.createMesh();
  }

  get visible () {
    return this._visible;
  }

  set visible (value) {
    this._visible = value;
  }

  getSizes () {
    this._sizes.set(Metrics.WIDTH, Metrics.HEIGHT);
    this._offset.set(0, 0);
  }

  createMesh () {
    this._geometry = new THREE.PlaneBufferGeometry(1, 1, 16, 16);

    const imageAspect = this._sizes.height / this._sizes.width;
    let a1;
    let a2;
    let a3;
    let a4;

    if (HEIGHT / WIDTH_BACKGROUND > imageAspect) {
      const height = (WIDTH_BACKGROUND / HEIGHT) * imageAspect;
      // Canvas more horizontal than image
      // Sets the layers 0 and 2: width screen 100%, height bigger proportionally
      a1 = 1;
      a2 = height;

      // Sets layer 1 height same height other layers and widht proportionally to height increment
      a3 = (HEIGHT / WIDTH_FOREGROUND) / height;
      a4 = height;
    } else {
      // Canvas more vertical than image
      // Sets all layers: height bigger 125% and width screen proportionally
      a1 = ((HEIGHT / WIDTH_BACKGROUND) / imageAspect) * 0.8;
      a2 = 0.8;
      a3 = ((HEIGHT / WIDTH_FOREGROUND) / imageAspect) * 0.8;
      a4 = 0.8;
    }

    const uniforms = {
      texture1: { type: 'sampler2D', value: this._layers[0] },
      texture2: { type: 't', value: this._layers[1] },
      texture3: { type: 't', value: this._layers[2] },
      progress: { type: 'f', value: 0.0 },
      alpha: { type: 'f', value: 0.0 },
      resolutionY: {
        type: 'v2', value: {
          x: a1,
          y: a2
        }
      },
      resolutionX: {
        type: 'v2', value: {
          x: a3,
          y: a4
        }
      }
    };

    this._material = new THREE.ShaderMaterial({
      uniforms,
      fragmentShader: FRAGMENT_BASIC,
      vertexShader: VERTEX_BASIC,
      transparent: true
    });

    this._mesh = new THREE.Mesh(this._geometry, this._material);
    this._mesh.position.set(this._offset.x, this._offset.y, 0);
    this._mesh.scale.set(this._sizes.x, this._sizes.y, 1);

    this._scene.add(this._mesh);
  }

  resize () {
    this.getSizes();
    this._mesh.scale.set(this._sizes.x, this._sizes.y, 1);
  }

  dispose () {
    this.container.remove(this.mesh);
    Main.stage.scene.remove(this.container);
    this.disposeTexture();
    this.mesh.material.dispose();
    this.mesh.geometry.dispose();
  }

  log () {
    Hud.console.h2(this.consoleTitle);
    Hud.console.log(this.consoleSubTitle);
    Hud.console.log(this.consoleText);
    Hud.console.separator();
  }
}
