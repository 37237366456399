import { Sizes } from "../_app/cuchillo/core/Sizes";
import InterfaceCanvas from "../_app/cuchillo/layout/InterfaceCanvas";
import Hud from './Hud';

export default class HudLogo {
  image = new Image();
  canvas = InterfaceCanvas.canvas || document.createElement('canvas');
  ctx = InterfaceCanvas.ctx || this.canvas.getContext('2d');
  dom;
  x;
  y;
  width;
  height;
  ratio;
  enabled = false;

  constructor(__dom, __src) {
    this.dom = __dom;
    this.image.onload = () => {
      this.enabled = true;
    };
    this.image.src = __src;
    this.width = this.size;
  }

  draw() {
    if(this.enabled) {
      this.ctx.drawImage(this.image, this.x, this.y, this.width, this.height);
      this.ctx.restore();
    }
  }

  resize() {
    const size = this.dom.getBoundingClientRect(); 
    this.x = size.x * Sizes.RATIO_HUD;
    this.y = size.y * Sizes.RATIO_HUD - Hud.grid;
    this.width = size.width * 1.10 * Sizes.RATIO_HUD;
    this.height = size.height * 1.10 * Sizes.RATIO_HUD;
  }
}